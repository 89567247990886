import "./About.scss";
import aboutImg from "../../Media/about.webp";
import { ReactComponent as Checkin } from "../../Media/svg/checkin.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import aboutImages from "../../Media/About/index";
const About = () => {
  const pointData = [
    "Terintegrasi Langsung dengan Stasiun KRL Baru",
    "Desain Fasad Segitiga Unik dan Modern Minimalis",
    "Harga Terjangkau dengan Banyak Tipe Pilihan",
    "Tersedia 2.933 Unit Rumah Tapak yang Luas",
  ];
  const waOpen = () => {
    window.open(
      "https://api.whatsapp.com/send/?phone=6282122915242&text=Halo+Novi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20https://official-samestaparayasa.com/&type=phone_number&app_absent=0",
      "_blank"
    );
  };
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyload: true,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  return (
    <section className="section-about" id="about">
      <div className="container-about">
        <div className="text">
          <div className="label">Samesta Parayasa</div>
          <h4 className="title">Hunian Terintegrasi yang Membawa Kemakmuran</h4>
          <p className="desc">
            Samesta Parayasa hadir sebagai solusi hunian modern yang memadukan
            kemewahan, kenyamanan, serta konektivitas transportasi publik yang
            terintegrasi. Terinspirasi dari filosofi "Para" (Parung Panjang) dan
            "Yasa" (membangun, kemuliaan, kemakmuran), Samesta Parayasa siap
            menjadi tempat tinggal impian yang akan membawa kemakmuran bagi
            seluruh penghuninya.
          </p>
          {pointData.map((point) => (
            <div className="points">
              <Checkin
                width={21}
                height={21}
                fill={"#566878"}
                className="icon"
              ></Checkin>
              <span>{point}</span>
            </div>
          ))}
          <button onClick={waOpen} className="btn-navbar">
            Learn More
          </button>
        </div>
        <div className="image">
          <img src={aboutImg} alt="about samesta parayasa" />
        </div>
      </div>
      <div className="slider">
        <Slider {...settings}>
          {aboutImages.map((image) => (
            <div className="slide">
              <img src={image} alt="about samesta parayasa" />
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default About;
