import React from "react";
import "./Home.scss";
import Hero from "../Section/Hero/Hero";
import About from "../Section/About/About";
import Promo from "../Section/Promo/Promo";
import Product from "../Section/Product/Product";
import Lokasi from "../Section/Lokasi/Lokasi";
import ScrollToAnchor from "./ScrollToAnchor";
const Home = () => {
  return (
    <div className="home">
      <ScrollToAnchor />
      <Hero />
      <About />
      <Promo />
      <Product />
      <Lokasi />
    </div>
  );
};

export default Home;
