import bluebella from "../../Media/Product/Bluebell/Bluebell A.webp";
import bluebellb from "../../Media/Product/Bluebell/Bluebell B.webp";
import bluebellc from "../../Media/Product/Bluebell/Bluebell C.webp";
import bougenvillea from "../../Media/Product/Bougenville/Bougenville A.webp";
import bougenvilleb from "../../Media/Product/Bougenville/Bougenville B.webp";
import bougenvillec from "../../Media/Product/Bougenville/Bougenville C.webp";
import bpka from "../../Media/Product/BPK Residence/BPK A.webp";
import bpkb from "../../Media/Product/BPK Residence/BPK B.webp";
import tropicala from "../../Media/Product/New Tropical/Tropical A.webp";
import tropicalb from "../../Media/Product/New Tropical/Tropical B.webp";
import ruko from "../../Media/Product/Ruko/Ruko.webp";

const bgna = [bougenvillea, bougenvillea];
const bgnb = [bougenvilleb, bougenvilleb];
const bgnc = [bougenvillec, bougenvillec];
const blba = [bluebella, bluebella];
const blbb = [bluebellb, bluebellb];
const blbc = [bluebellc, bluebellc];
const bpkaa = [bpka, bpka];
const bpkbb = [bpkb, bpkb];
const tpaa = [tropicala, tropicala];
const tpab = [tropicalb, tropicalb];
const rukoR = [ruko, ruko];

export { bgna, bgnb, bgnc, blba, blbb, blbc, bpkaa, bpkbb, tpaa, tpab, rukoR };
