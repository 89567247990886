import "./Hero.scss";
import heroImg from "../../Media/Hero/1-1.webp";

const Hero = () => {
  return (
    <section className="section-hero" id="hero">
      <div className="container-bg"></div>
      <div className="container-content">
        <div className="hero-img">
          <img src={heroImg} alt="hero" />
        </div>
        <div className="hero-text">
          <h3>Wecome to Samesta Parayasa</h3>
          <p>
            Samesta Parayasa hadir sebagai solusi hunian modern yang memadukan
            kemewahan, kenyamanan, serta konektivitas transportasi publik yang
            terintegrasi. Temukan rumah impian Anda di Samesta Parayasa, di mana
            kemakmuran dan kemuliaan akan menyambut Anda setiap hari.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
