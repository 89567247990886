import "./Lokasi.scss";
import map from "../../Media/map.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons";

const Lokasi = () => {
  return (
    <section className="section-lokasi" id="lokasi">
      <h2 className="lokasi-title">Lokasi</h2>
      <div className="container-lokasi">
        <div className="lokasi-img">
          <img src={map} alt="map" />
        </div>
        <div className="lokasi-point">
          <ul>
            <li>
              <FontAwesomeIcon icon={faLocationCrosshairs} className="icon" />
              <span>20 Menit ke QBIG BSD</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faLocationCrosshairs} className="icon" />
              <span>20 Menit The Breeze BSD</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faLocationCrosshairs} className="icon" />
              <span>20 Menit AEON Mall</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faLocationCrosshairs} className="icon" />
              <span>25 Menit Super Mall Karawaci</span>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Lokasi;
