import "./App.css";
import Home from "./Pages/Home";
import Navbar from "./Section/Navbar/Navbar";
import Footer from "./Section/Footer/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { FloatingWhatsApp } from "react-floating-whatsapp";
function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <a
        rel="noreferrer"
        target="_blank"
        href="https://api.whatsapp.com/send/?phone=6282122915242&text=Halo+Novi%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20ini%20https://official-samestaparayasa.com/&type=phone_number&app_absent=0"
      >
        <FloatingWhatsApp />
      </a>
      <Footer />
    </Router>
  );
}

export default App;
