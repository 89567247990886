import "./Product.scss";
import CardProdukW from "../../Component/Card/CardProdukW";
import CardProdukD from "../../Component/Card/CardProdukD";
import cardProduk from "./index.json";
import {
  bgna,
  bgnb,
  bgnc,
  blba,
  blbb,
  blbc,
  bpkaa,
  bpkbb,
  tpaa,
  tpab,
  rukoR,
} from "../../Media/Product/imagesMap";
const imageMap = {
  bgna,
  bgnb,
  bgnc,
  blba,
  blbb,
  blbc,
  bpkaa,
  bpkbb,
  tpaa,
  tpab,
  rukoR,
};

const Product = () => {
  return (
    <section className="section-product" id="rumah">
      <div id="bougenville">
        <div className="container">
          <div className="label">Our Project</div>
          <h2 className="title">Bougenville</h2>
          <div className="container-card">
            {cardProduk.cardsBougenville.map((data, index) => (
              <CardProdukW
                key={index}
                carousel={imageMap[data.imagesKey]}
                cardID={data.cardID}
                title={data.title}
                downPaymentType={data.downPaymentType}
                price={data.price}
                details={data.details}
                whatsappLink={data.whatsappLink}
              />
            ))}
          </div>
        </div>
      </div>
      <div id="bpk-residence">
        <div className="container">
          <div className="label">Our Project</div>
          <h2 className="title">BPK Residence</h2>
          <div className="container-card">
            {cardProduk.cardsBPK.map((data, index) => (
              <CardProdukD
                key={index}
                carousel={imageMap[data.imagesKey]}
                cardID={data.cardID}
                title={data.title}
                downPaymentType={data.downPaymentType}
                price={data.price}
                details={data.details}
                whatsappLink={data.whatsappLink}
              />
            ))}
          </div>
        </div>
      </div>
      <div id="bluebell">
        <div className="container">
          <div className="label">Our Project</div>
          <h2 className="title">Bluebell</h2>
          <div className="container-card">
            {cardProduk.cardsBluebell.map((data, index) => (
              <CardProdukW
                key={index}
                carousel={imageMap[data.imagesKey]}
                cardID={data.cardID}
                title={data.title}
                downPaymentType={data.downPaymentType}
                price={data.price}
                details={data.details}
                whatsappLink={data.whatsappLink}
              />
            ))}
          </div>
        </div>
      </div>
      <div id="tropical">
        <div className="container">
          <div className="label">Our Project</div>
          <h2 className="title">Tropical</h2>
          <div className="container-card">
            {cardProduk.cardsTropical.map((data, index) => (
              <CardProdukD
                key={index}
                carousel={imageMap[data.imagesKey]}
                cardID={data.cardID}
                title={data.title}
                downPaymentType={data.downPaymentType}
                price={data.price}
                details={data.details}
                whatsappLink={data.whatsappLink}
              />
            ))}
          </div>
        </div>
      </div>
      <div id="ruko-commercial">
        <div className="container">
          <div className="label">Our Project</div>
          <h2 className="title">Ruko Commercial</h2>
          <div className="container-card" style={{ justifyContent: "center" }}>
            {cardProduk.cardsRuko.map((data, index) => (
              <CardProdukW
                key={index}
                carousel={imageMap[data.imagesKey]}
                cardID={data.cardID}
                title={data.title}
                downPaymentType={data.downPaymentType}
                price={data.price}
                details={data.details}
                whatsappLink={data.whatsappLink}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Product;
